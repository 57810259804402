type VideoMetadata = {
    aspectRatio: number;
    title: string;
    thumbnailSrc: string;
    thumbnailHeight: number;
    thumbnailWidth: number;
};

type NoEmbedResponse = {
    title: string;
    thumbnail_url: string;
    thumbnail_height: number;
    thumbnail_width: number;
    width: number;
    height: number;
};

const requestCache: { [key: string]: Promise<NoEmbedResponse> } = {};

export const fetchVideoMetadata = async (src: string): Promise<VideoMetadata> => {
    let request = requestCache[src];

    if (!request) {
        request = fetch(`https://noembed.com/embed?url=${src}`).then((r) => r.json());
        requestCache[src] = request;
    }

    const { thumbnail_url, thumbnail_height, thumbnail_width, width, height, title } =
        await request;

    return {
        thumbnailSrc: thumbnail_url,
        thumbnailHeight: thumbnail_height,
        thumbnailWidth: thumbnail_width,
        aspectRatio: height / width,
        title: title,
    };
};
